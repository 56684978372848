
<template>
  <div class="h-full relative">
    <module-form-header label="Patientinformation" :patient="info" :data="{}" />

    <form @submit.prevent="updatePatient" class="cm-form p-4">
      <div class="flex-col">
        <div class="flex">
          <div>
            <label for="first_name"> Förnamn </label>
            <cm-input-text id="first_name" v-model="patient.first_name" />
          </div>
          <div>
            <label for="last_name"> Efternamn </label>
            <cm-input-text id="last_name" v-model="patient.last_name" />
          </div>
        </div>
        <div class="flex ml-2">
          <div>
            <label for="ssn"> Personnummer </label>
            <cm-input-text id="ssn" v-model="patient.ssn" />
          </div>
          <div>
            <label for="ssn"> Kön </label>
            <cm-select id="ssn" v-model="patient.gender" :options="genderOptions" />
          </div>
        </div>
      </div>

      <div class="flex-col mb-2">
        <patient-consent
          @consent="consentEdit"
          :consents="studyConsents"
          :study="true"
          :label="$t('patient.consent.study')"
        />
      </div>

      <hr />

      <div class="mr-4 flex flex-col md:flex-row p-4">
        <div class="lg:w-1/3">
          <!-- Patient comment is removed from patient table but is a good feature maybe save as patient variable later? -->
          <label class="block text-base">{{ $t("patient.patient_comment") }}</label>
        </div>
        <div class="relative flex flex-grow">
          <cm-textarea v-model="patient.patient_comment" name="patient_comment" />
        </div>
      </div>

      <div class="flex justify-start py-2 mt-4">
        <cm-button type="submit" class="btn btn-green mr-2">{{ $t("button.save") }}</cm-button>
        <div>
          <cm-button class="btn btn-white mr-2 focus:border-2" size="medium" @click.prevent="deletePatient">{{
            $t("button.delete")
          }}</cm-button>
        </div>
        <cm-button type="button" class="btn btn-white mr-2 focus:border-2" @click="$modal.hide('patient-edit')">{{
          $t("button.cancel")
        }}</cm-button>
      </div>
    </form>

    <modal
      name="consent-edit"
      :clickToClose="false"
      :height="'auto'"
      classes="bg-white rounded-lg shadow flex flex-col justify-between"
    >
      <consent-edit :info="info" :data="consent" />
    </modal>

    <modal
      name="consent-add"
      :clickToClose="false"
      :height="'auto'"
      classes="bg-white rounded-lg shadow flex flex-col justify-between"
    >
      <consent-add :info="info" />
    </modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ModuleFormHeader from "@/components/patient/module/ModuleFormHeader";
import PatientConsent from "@/components/patient/consent/PatientConsent";

export default {
  name: "PatientEdit",
  props: ["info"],
  components: {
    PatientConsent,
    ModuleFormHeader,
  },
  data() {
    return {
      patient: {
        first_name: this.info.first_name,
        last_name: this.info.last_name,
        ssn: this.info.ssn,
        gender: this.info.gender,
        bas_consent: this.info.bas_consent,
        bas_consent_date: this.info.bas_consent_date,
        bas_consent_name: this.info.bas_consent_name,
        bas_inclusion_date: this.info.bas_inclusion_date,
        excluded_date: this.info.excluded_date,
        excluded_reason: this.info.excluded_reason,
        patient_comment: this.info.patient_comment,
      },
    };
  },
  created() {
    console.log(this.info);
    //   this.load();
  },
  methods: {
    updatePatient() {
      this.pendingSave = true;
      this.$store.dispatch("patient/update", this.patient).then(() => {
        this.$modal.hide("patient-edit");
      });
    },
    deletePatient() {
      let buttons = [
        {
          title: this.$t("button.cancel"),
          handler: () => {
            this.$modal.hide("dialog");
          },
        },
        {
          title: this.$t("button.continue"),
          class: "vue-dialog-button bg-red-500 text-white hover:bg-red-600",
          handler: () => {
            this.$modal.hide("dialog");
            this.$store.dispatch("patient/delete").then(() => {
              this.$router.push({ name: "user-patients" });
            });
          },
        },
      ];
      this.$modal.show("dialog", {
        title: this.$t("patient.dialog.delete.title"),
        text: this.$t("patient.dialog.delete.text"),
        buttons: buttons,
      });
    },

    options(varname) {
      if (this.structure) {
        for (var i = 0; i < this.structure.variables.length; i++) {
          if (this.structure.variables[i].varname == varname) {
            return this.structure.variables[i].optionslist;
          }
        }
      }
    },
    consentEdit(consent) {
      this.consent = consent;
    },
    // async load() {
    //   await this.$store.dispatch("meta/loadFormStructure", "patient");
    // },
  },
  computed: {
    consentOptions() {
      return [
        { value: null, translation: { label: "" } },
        { value: -1, translation: { label: "Ej inhämtat" } },
        { value: 1, translation: { label: "Ja" } },
        { value: 0, translation: { label: "Nej" } },
      ];
    },
    excludeOptions() {
      return [
        { value: null, translation: { label: "" } },
        { value: 1, translation: { label: "Patienten avböjer" } },
        { value: 2, translation: { label: "Dödsfall" } },
        { value: 3, translation: { label: "Övriga orsaker" } },
        { value: 4, translation: { label: "Patienten avböjer" } },
      ];
    },
    ...mapState("meta", {
      structure: state => state.formstructure,
    }),
    minConsentDate() {
      return this.info.diagnoses[0].onset_date ? this.info.diagnoses[0].onset_date : null;
    },
    maxDate() {
      return new Date();
    },
    registryConsents() {
      console.log(this.info.consents);
      return this.info.consents.filter(d => d.registry);
    },
    studyConsents() {
      return this.info.consents.filter(d => d.study);
    },
    genderOptions() {
      return [
        { value: "M", translation: { label: "Man" } },
        { value: "K", translation: { label: "Kvinna" } },
      ];
    },
  },
};
</script>
